//Libs
import React, { useState, useCallback } from "react";
import { connect } from "react-redux";
import * as imageConversion from "image-conversion";
import { CgSpinner } from "react-icons/cg";
//Selectors
import { selectCurrentUser } from "Redux/User/user-selectors";
import { selectedOrganizationId } from "Redux/Organization/organization-select";
//Actions
import { sendMessage, setProfilePhotoUpdated } from "Redux/User/user-actions";
import { getOptimizedStories } from "Redux/Stories/stories-actions";
import { setCurrentProfilePhoto } from "Redux/User/user-actions";
//Services
import { saveProfilePhoto } from "Services/UserService";
import { saveStory } from "Services/StoryService";
//Components
import {
  Wrapper,
  Modal,
  Image,
  FloatCheckButton,
  FloatLoadingButton,
  FloatFrownButton,
  FloatCameraButton,
  FormInput,
} from "Components";

function PreviewProfilePhoto({
  id,
  handleChange,
  canPreview,
  preview,
  uploaded,
  uploading,
  seeMore,
  seeMoreUrl,
  onOk,
  onCancel,
  onChangeSeeMoreUrl,
  isStory,
}) {
  const width =
    id === "storyResource" ? "180px" : id === "profilePhoto" ? "268px" : 0;
  const height =
    id === "storyResource" ? "320px" : id === "profilePhoto" ? "268px" : 0;

  return (
    <Modal visible={!!preview} onCancel={onCancel} footer={null}>
      <Wrapper position="relative" textAlign="center" padding="10px">
        {canPreview ? (
          <Image
            isStory={isStory}
            circular={id === "profilePhoto"}
            width={width}
            height={height}
            src={preview}
          />
        ) : (
          <div
            style={{
              marginBottom: "65px",
              color: "#fff",
            }}
          >
            Vista previa no disponible. Puede subir su recurso.
          </div>
        )}
        <InputFile
          id={id}
          handleChange={handleChange}
          children={
            <FloatCameraButton
              padding="10px"
              fontSize="1.5rem"
              bottom="0"
              left="50%"
              transform="translate(-50%, 0)"
            />
          }
        />

        {uploading && (
          <CgSpinner className="text-white text-5xl animate-spin float-right mb-6" />
        )}
        {!uploading && uploaded === null && (
          <FloatCheckButton
            onClick={onOk}
            bottom="0"
            right="0"
            transform="translate(-50%, 0)"
          />
        )}
        {!uploading && uploaded === false && (
          <FloatFrownButton
            onClick={onOk}
            bottom="0"
            right="0"
            transform="translate(-50%, 0)"
          />
        )}
      </Wrapper>
      {seeMore && (
        <React.Fragment>
          <FormInput
            seeMoreLabel
            name="seeMoreUrl"
            type="text"
            maxLength={255}
            value={seeMoreUrl}
            onChange={(e) => onChangeSeeMoreUrl(e.target.value)}
            label="Ver más..."
          />
          <div
            style={{
              textAlign: "center",
              color: "#fff",
              fontSize: "12px",
              margin: "0px 4vw",
            }}
          >
            <span>
              Si desea que el usuario al hacer click se dirija a un sitio en
              particular indique una dirección web en "Ver más..."
            </span>
          </div>
        </React.Fragment>
      )}
    </Modal>
  );
}

function InputFile({ id, handleChange, children }) {
  return (
    <>
      <input
        id={id}
        type="file"
        accept="image/*,video/mp4,video/webm"
        style={{ display: "none" }}
        onChange={handleChange}
      />

      <label htmlFor={id}>{children}</label>
    </>
  );
}

const getInitialState = () => ({
  file: undefined,
  preview: undefined,
  isVideo: false,
  seeMoreUrl: undefined,
  uploading: false,
  uploaded: null,
});

function ResourceUpload({
  //External Props
  id,
  children,
  //Internal Props
  currentUser,
  sendMessage,
  setProfilePhotoUpdated,
  getOptimizedStories,
  seeMore,
  isStory,
  organizationId,
  setCurrentProfilePhoto,
}) {
  const [image, setImageProps] = useState(getInitialState());
  const executeTriggerUploader = useCallback(() => {
    switch (id) {
      case "profilePhoto": {
        setImageProps({ ...image, uploading: true });
        saveProfilePhoto(
          currentUser.token,
          image.file,
          sendMessage,
          organizationId
        ).then((url) => {
          if (url) {
            setCurrentProfilePhoto(url);
            setImageProps({ ...image, uploading: false, uploaded: false });
          }
          if (!!url) {
            setProfilePhotoUpdated();
            setImageProps(getInitialState(null));
          }
        });
        break;
      }
      case "storyResource": {
        setImageProps({ ...image, uploading: true });
        saveStory(
          currentUser.token,
          currentUser.id,
          image.file,
          image.isVideo,
          image.seeMoreUrl,
          sendMessage,
          organizationId
        ).then((res) => {
          if (res) {
            setImageProps({ ...image, uploading: false, uploaded: false });
          }
          if (!!res) {
            setImageProps(getInitialState(null));
            getOptimizedStories(
              currentUser.token,
              currentUser.id,
              organizationId
            );
          }
        });
        break;
      }
      default:
        return;
    }
  }, [
    id,
    image,
    currentUser,
    getOptimizedStories,
    organizationId,
    sendMessage,
    setCurrentProfilePhoto,
    setProfilePhotoUpdated,
  ]);

  const getPreviewResource = useCallback((file) => {
    if (file.type.includes("image")) {
      return URL.createObjectURL(file);
    } else if (file.type.includes("video")) {
      return file;
    }
    return false;
  }, []);

  const handleChangeFile = (e) => {
    const file = e.target?.files[0];
    if (!file) setImageProps(getInitialState(null));
    else {
      if (file.type.includes("image")) {
        imageConversion
          .compress(file, {
            quality: 0.8,
            type: "image/jpg", //Debe estar en jpg para que realice la compresión, de lo contrario no lo hace.
            orientation: 1,
          })
          .then((output) => {
            setImageProps({
              ...image,
              file: output,
              preview: getPreviewResource(output),
              isVideo: false,
            });
          });
      } else if (file.type.includes("video")) {
        if (file && file.type === "video/mp4") {
          const sizeVideo = file.size / 1024 / 1024;
          if (sizeVideo <= 10) {
            setImageProps({
              ...image,
              file,
              preview: getPreviewResource(file),
              isVideo: true,
            });
          } else {
            alert(
              "El archivo es demasiado grande, no lo podemos subir, debe ser menor a 10MB. Te recomendamos transformar el vídeo con aplicaciones como instagram o tiktok. También puedes utilizar este sitio web: https://www.mp4compress.com/es/"
            );
          }
        }
      }
    }
  };

  const onChangeSeeMoreUrl = (seeMoreUrl) => {
    setImageProps({ ...image, seeMoreUrl });
  };
  return (
    <>
      <InputFile id={id} handleChange={handleChangeFile} children={children} />

      {!!image.preview && (
        <PreviewProfilePhoto
          isStory={isStory}
          seeMore={seeMore}
          id={id}
          handleChange={handleChangeFile}
          canPreview={!(!!image.preview && !!image.preview.type)} // Si tiene type es un video por lo que no se debe mostar la vista previa
          preview={image.preview}
          uploading={image.uploading}
          uploaded={image.uploaded}
          seeMoreUrl={image.seeMoreUrl}
          onChangeSeeMoreUrl={onChangeSeeMoreUrl}
          onCancel={() => setImageProps(getInitialState(null))}
          onOk={() => {
            if (image.file) {
              executeTriggerUploader();
            }
          }}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  //User
  currentUser: selectCurrentUser(state),
  organizationId: selectedOrganizationId(state),
});

const mapDispatchToProps = (dispatch) => ({
  //User
  sendMessage: (payload) => dispatch(sendMessage(payload)),
  setProfilePhotoUpdated: () => dispatch(setProfilePhotoUpdated()),
  //Story
  getOptimizedStories: (token, userId, organizationId) =>
    dispatch(getOptimizedStories(token, userId, organizationId)),
  setCurrentProfilePhoto: (photo) => dispatch(setCurrentProfilePhoto(photo)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(ResourceUpload));
