import React, { useEffect, useState } from "react";
import { questionsService, sendAnswerService } from "Services/QuestionService";
import { CompanyService } from "Services/CompanyService";
//Component
import Test from "Components/TestForm/Test";
import { Spinner } from "Components";
import { useForm } from "react-hook-form";
//router
import { ROUTES } from "Config/config";
import { useParams, useHistory } from "react-router";
//Redux
import { connect } from "react-redux";
// USER REDUX
import { selectCurrentUser } from "Redux/User/user-selectors";
import { logout } from "Redux/User/user-actions";
//Service
import MixPanel from "Services/MixPanelService";
import {
  orgnizacionUnseleted,
  dccLogout,
} from "Redux/DynamicConfigCompany/dcc-action";
// ORGANIZATION
import { clearOrganization } from "Redux/Organization/organizarion-action";
import { Modal } from "antd";

const error = ({ title = "This is an error message", content }) => {
  Modal.error({
    title,
    content,
    onOk() {},
  });
};

const QuizView = ({
  clearOrganization,
  unselected,
  dccLogoutCompany,
  currentUser,
}) => {
  const history = useHistory();
  const [answersFiltered, setAnswersFiltered] = useState([]);
  const [evaluation, setEvaluation] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [lodingAnswer, setLoadingAnswer] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [textModal, setTextModal] = useState({
    approved: false,
    modalTitle: "",
    modalInfo: "No tiene preguntas disponibles.",
    finishTest: false,
  });

  const [infoMsg, setInfoMsg] = useState({
    error: "",
    success: "",
  });
  const [questionDataState, setQuestionDataState] = useState({
    question: null,
    pagination: null,
  });
  const { register, handleSubmit } = useForm();
  const { token } = currentUser;
  const [showEvaluation, setShowEvaluation] = useState({
    isBlocked: false,
    msg: "",
  });
  const { typeOfForm, resourceId, organizationId } = useParams();

  useEffect(() => {
    const getQuestions = async () => {
      setIsLoading(true);
      const evaluationRes = await questionsService({
        token,
        resourceId,
        organizationId,
      }).then((response) => response);
      setIsLoading(false);

      if (evaluationRes === 400) {
        setShowEvaluation({
          isBlocked: false,
          msg: "Usted ya completó este examen.",
        });
      }

      if (evaluationRes === 409) {
        setShowEvaluation({
          isBlocked: true,
          msg: "Debes completar el Examen del bloque anterior para continuar con el de este bloque.",
        });
      }

      setQuestionDataState({
        questions: evaluationRes?.questions,
        pagination: evaluationRes.pagination,
      });
      setEvaluation(evaluationRes);
    };
    getQuestions();
  }, [token, resourceId, organizationId]);

  const sendAnswer = async (data) => {
    const { token } = currentUser;

    MixPanel.track(MixPanel.TYPES.REACTION_WITH_CHAT_ROOM, {
      userID: currentUser.id,
      userName: currentUser.name,
      userLastName: currentUser.firstLastName,
      userEmail: currentUser.email,
      organizationId: organizationId,
      resourceId: resourceId,
    });

    let answers = Object.values(data);
    const answersFilter = answers.filter((value) => value !== null);
    setAnswersFiltered(answersFilter);

    if (answersFilter.length > 0) {
      setLoadingAnswer(true);
      const response = await sendAnswerService({
        answerIds: answersFilter,
        token,
        resourceId,
        organizationId,
      }).then((rest) => rest);
      const answerRes = await response.json();
      setLoadingAnswer(false);
      if (answerRes.success) {
        setIsModalOpen(true);
        if (answerRes.approved) {
          MixPanel.track(MixPanel.TYPES.FINAL_RESULT_TEST_SCORE, {
            userID: currentUser.id,
            userName: currentUser.name,
            userLastName: currentUser.firstLastName,
            userEmail: currentUser.email,
            organizationId: organizationId,
            resourceId: resourceId,
            approved: answerRes.approved,
            msg: answerRes.msg,
          });
          if (parseInt(organizationId) !== 6) {
            setTextModal({
              approved: true,
              modalTitle: "FELICIDADES",
              modalInfo:
                answerRes.msg +
                " Tienes activo el próximo bloque y para poder visualizarlo debes de cerrar sesión e iniciar nuevamente.",
              finishTest: true,
            });
          } else {
            setTextModal({
              approved: true,
              modalTitle: "FELICIDADES",
              finishTest: true,
              modalInfo:
                answerRes.msg +
                "Ponte en contacto con tus lideres para consultarles por la fecha de la graduación.",
            });
          }
        } else {
          MixPanel.track(MixPanel.TYPES.FINAL_RESULT_TEST_SCORE, {
            userID: currentUser.id,
            userName: currentUser.name,
            userLastName: currentUser.firstLastName,
            userEmail: currentUser.email,
            organizationId: organizationId,
            resourceId: resourceId,
            approved: answerRes.approved,
            msg: answerRes.msg,
          });
          setTextModal({
            approved: false,
            modalTitle: "Lo sentimos",
            finishTest: true,
            modalInfo:
              answerRes.msg +
              "Debes volver a llevar el bloque correspondiente.",
          });
        }
      }
      if (!answerRes.success) {
        if (answerRes === 409) {
          setInfoMsg({
            error: "Ya has respondido esta evaluación.",
          });
          history.push(ROUTES.HOME);
          return;
        }

        error({
          title: (
            <span className="text-white text-center">
              "No se ha podido enviar sus respuestas, intenta nuevamente.",
            </span>
          ),
          content: (
            <div className=" flex-col justify-center items-center">
              <span className="text-white text-center font-bold text-lg">
                Copie la siguiente información y envíela a soporte
              </span>
              <br />
              <span className="text-white text-center ">
                mensaje: {answerRes?.message}
              </span>
            </div>
          ),
        });

        // setInfoMsg({
        //   error: "Ha ocurrido un error, intenta nuevamente.",
        // });
      }

      if (answerRes === 401) {
        setInfoMsg({
          error: "Su Token a expirado.",
        });

        setTimeout(async () => {
          const companyData = await CompanyService.getCompanyData(
            window.location.host
          ).then((data) => data);
          clearOrganization();
          dccLogoutCompany();
          unselected(companyData.company);
          logout();
          history.push(ROUTES.SIGNIN);

          setInfoMsg({
            error: "",
            success: "",
          });
        }, 5000);
      }
    } else {
      setInfoMsg({
        error: "No ha respondido ninguna pregunta.",
      });
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  return Array.isArray(evaluation?.questions) ? (
    <Test
      questionUtil={{
        infoMsg,
        isModalOpen,
        lodingAnswer,
        organizationId: parseInt(organizationId),
        pagination: evaluation.pagination,
        questions: evaluation.questions,
        resourceId,
        sendAnswer,
        setEvaluation,
        setIsLoading,
        setIsModalOpen,
        setQuestionDataState,
        textModal,
        totalQuestions: evaluation.pagination.totalItems,
        token,
      }}
      typeOfTest={typeOfForm}
      useForm={{ handleSubmit, register }}
    />
  ) : (
    <div className="min-h-screen w-screen flex justify-center items-center px-8">
      <span className="text-white text-lg">{showEvaluation.msg}</span>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentUser: selectCurrentUser(state),
});

const mapDispatchToProps = (dispatch) => ({
  clearOrganization: () => dispatch(clearOrganization()),
  dccLogoutCompany: () => dispatch(dccLogout()),
  unselected: (company) => dispatch(orgnizacionUnseleted(company)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuizView);
