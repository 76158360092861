//Config
import { API_URL } from "Config/config";
import FetchPlus from "./FetchPlus";

export const questionsService = async ({
  token,
  resourceId,
  organizationId,
  size = 6,
  currentPage = 0
}) => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  return FetchPlus(`${API_URL}resource/${resourceId}/organization/${organizationId}/?size=${size}&page=${currentPage}`,
  options
  )
    .then((response) => response)
    .catch((error) => error);
};

export const sendAnswerService = async ({ answerIds, token, resourceId, organizationId }) => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify({
      answerIds
    }),
  };
  return fetch(`${API_URL}resource/${resourceId}/organization/${organizationId}`, options)
    .then((response) => response)
    .catch((error) => error);
};
