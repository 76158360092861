import tw from "tailwind-styled-components";

const CustomButton = tw.button`
    ${(props) =>
      props.$singbutton &&
      "min-w-b-15 w-auto h-10 line-h py-2 px-2 text-md" +
        "leading-10 bg-primary-button text-primary-button font-semibold font-primary-font-family" +
        "border-none rounded-3xl cursor-pointer flex justify-center mb-7"};
    ${(props) =>
      props.$singupbutton &&
      "min-w-b-15 w-10/12 h-12 line-h py-2 px-5 text-xs lg:text-xl " +
        "leading-10 bg-primary-button text-primary-button font-semibold font-primary-font-family " +
        "border-none rounded-sm cursor-pointer flex justify-center mb-3"};  
    ${(props) =>
      props.$modalbutton &&
      "min-w-b-15 w-3/12 h-10 line-h py-2 px-5 text-xs " +
        "leading-10 bg-primary-button text-primary-button font-semibold font-primary-font-family " +
        "border-none rounded-sm cursor-pointer flex justify-center mb-7"};
    ${(props) =>
      props.$ButtonConfigUser &&
      "min-w-b-15 w-full h-10 line-h py-2 px-5 text-xs " +
        "leading-10 bg-primary-button text-primary-button font-semibold font-primary-font-family " +
        "border-none rounded-sm cursor-pointer flex justify-center mb-7"};       
`;

export default CustomButton;
