import React, { useState } from "react";
//Libs
import { Pagination, Typography } from "antd";
//Service
import { questionsService } from "Services/QuestionService";
import { CompanyService } from "Services/CompanyService";

//Component
import Question from "Components/Question/Question";
import { Button, InformativeMsg, Modal, SpinnerButton } from "Components";
//router
import { ROUTES } from "Config/config";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { logout } from "Redux/User/user-actions";
//Service
// DCC REDUX
import {
  orgnizacionUnseleted,
  dccLogout,
} from "Redux/DynamicConfigCompany/dcc-action";
import { connect } from "react-redux";
import { clearOrganization } from "Redux/Organization/organizarion-action";
// ORGANIZATION
const Test = ({
  clearOrganization,
  dccLogoutCompany,
  logout,
  questionUtil,
  typeOfTest,
  unselected,
  useForm,
}) => {
  const { handleSubmit, register } = useForm;
  const {
    infoMsg,
    isModalOpen,
    lodingAnswer,
    organizationId,
    pagination,
    questions,
    sendAnswer,
    setEvaluation,
    setIsLoading,
    setQuestionDataState,
    textModal,
    totalQuestions,
    token,
    resourceId,
  } = questionUtil;

  const formCod = organizationId - 2;
  const pageSize = 6;
  const [paginationAPI, setPaginationAPI] = useState({
    totalPage: pagination.totalItems,
    current: pagination.currentPage + 1,
    minIndex: 0,
    maxIndex: pageSize,
  });

  const type = {
    finalTest: "finalTest",
    singleTest: "singleTest",
  };

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return (
        <button
          className="ant-pagination-item-link px-2"
          style={{ fontSize: "14px", width: "112px" }}
        >
          Anterior
        </button>
      );
    }
    if (type === "next") {
      return (
        <button
          className="ant-pagination-item-link px-2"
          style={{ fontSize: "14px", width: "112px" }}
        >
          Siguiente
        </button>
      );
    }
    return originalElement;
  };

  const handleChangePagination = async (page) => {
    setIsLoading(true);

    setPaginationAPI({
      current: page,
      minIndex: (page - 1) * pageSize,
      maxIndex: page * pageSize,
    });

    const response = await questionsService({
      token,
      resourceId,
      organizationId,
      currentPage: page - 1,
    }).then((response) => response);

    setQuestionDataState({
      questions: response?.questions,
      pagination: response.pagination,
    });
    setIsLoading(false);
    setEvaluation(response);
  };

  const { minIndex, maxIndex } = paginationAPI;
  const { Title } = Typography;
  const history = useHistory();

  switch (typeOfTest) {
    case type.finalTest:
      return (
        <>
          <div className="min-h-screen w-screen flex flex-col justify-center px-8 lg:px-20 xl:px-72 py-20 bg-form-background bg-cover bg-center">
            <div className="bg-white rounded-xl h-full w-full flex flex-col justify-center py-20 px-6 lg:px-12">
              <div className="flex flex-col w-full mb-4">
                {pagination && pagination?.currentPage === 0 && (
                  <>
                    <p className="text-black text-base lg:text-lg font-semibold text-justify">
                      Este es el examen que corresponde al BLOQUE {formCod},
                      como recomendaciones iniciales:
                    </p>
                    <ul className="list-decimal pl-8 my-3 text-black text-base lg:text-lg">
                      <li>Debes de haber visto todas las lecciones.</li>
                      <li>Lee bien cada pregunta antes de responder.</li>
                    </ul>
                    <p className="mb-8 text-black text-base lg:text-lg font-semibold text-justify">
                      Al finalizar el examen se te dará tu calificación y de ser
                      aprobado automáticamente tendrás disponible el siguiente
                      bloque, en caso de reprobar deberás volver a realizar el
                      bloque completo.
                    </p>
                  </>
                )}
              </div>
              <form onSubmit={handleSubmit(sendAnswer)}>
                {questions.map(
                  ({ answers, id, order, title }, index) =>
                    index >= minIndex &&
                    index < maxIndex && (
                      <Question
                        key={index}
                        frameWorkOfQuestion={{
                          answers,
                          questionId: id,
                          order,
                          title,
                        }}
                        register={register}
                      />
                    )
                )}

                {pagination?.currentPage === pagination?.totalPages &&
                  !textModal.finishTest && (
                    <div className="w-full flex justify-center lg:justify-start my-4">
                      <button
                        className="bg-primary-button hover:bg-primary-hover-button text-white font-semibold text-base rounded-md py-2 px-20"
                        type="submit"
                      >
                        {lodingAnswer ? (
                          <div className="flex gap-2">
                            <SpinnerButton />
                            <span className="text-white">Guardando...</span>
                          </div>
                        ) : (
                          "Enviar"
                        )}
                      </button>
                    </div>
                  )}
                {pagination?.currentPage === pagination?.totalPages &&
                  textModal.finishTest && (
                    <div className="w-full flex flex-col items-center ">
                      {textModal.modalTitle ? (
                        <Title
                          className="text-center"
                          style={{
                            color: "black",
                            fontWeight: "700",
                            fontSize: "24px",
                          }}
                        >
                          {textModal.modalTitle}
                          {textModal.approved ? (
                            <span className="block">
                              Has aprobado el examen
                            </span>
                          ) : (
                            <span className="block">
                              Has reprobado el examen
                            </span>
                          )}
                        </Title>
                      ) : (
                        <Title></Title>
                      )}
                      {textModal.modalInfo && (
                        <p
                          style={{
                            color: "black",
                            fontWeight: "400",
                            fontSize: "14px",
                          }}
                        >
                          {textModal.modalInfo}
                        </p>
                      )}
                      {textModal.approved &&
                      isModalOpen &&
                      organizationId !== 6 ? (
                        <>
                          <span>
                            Para que usted pueda visualizar el siguiente bloque,
                            debe de desloguearse y volver a ingresar al sistema,
                            al aceptar se le redirigira a la pagina de inciar
                            sesion.
                          </span>
                          <div
                            className="bg-primary-button hover:bg-primary-hover-button text-white font-semibold text-base rounded-md py-2 px-20"
                            onClick={async () => {
                              const companyData =
                                await CompanyService.getCompanyData(
                                  window.location.host
                                ).then((data) => data);
                              unselected(companyData.company);
                              clearOrganization();
                              history.push(ROUTES.SIGNIN);
                              dccLogoutCompany();
                              logout();
                            }}
                          >
                            Aceptar
                          </div>
                        </>
                      ) : (
                        <div className="my-4">
                          <Link to={ROUTES.HOME}>
                            <div className="bg-primary-button hover:bg-primary-hover-button text-white font-semibold text-base rounded-md py-2 px-20">
                              Salir
                            </div>
                          </Link>
                        </div>
                      )}
                      {textModal.finishTest && !textModal.approved && (
                        <div className="my-4">
                          <Link to={ROUTES.HOME}>
                            <div className="bg-red-700 hover:bg-primary-hover-button text-white font-semibold text-base rounded-md py-2 px-20">
                              Aceptar
                            </div>
                          </Link>
                        </div>
                      )}
                    </div>
                  )}
                {infoMsg.success && (
                  <InformativeMsg type="succeMsg" msg={infoMsg.success} />
                )}
                {infoMsg.error && (
                  <InformativeMsg type="error" msg={infoMsg.error} />
                )}
              </form>
              {/* <div className="flex justify-between ">
                <button
                  className="btn bg-blue-500 hover:bg-blue-700 text-gray-800 font-bold w-32"
                  disabled={
                    pagination && pagination?.currentPage === 0 ? true : false
                  }
                  onClick={handlePrevPage}
                >
                  Atrás
                </button>
                <button
                  className={`btn bg-blue-500 hover:bg-blue-700 text-gray-800 font-bold w-32`}
                  disabled={
                    pagination &&
                    pagination?.currentPage === pagination?.totalPages
                      ? true
                      : false
                  }
                  onClick={handleNextPage}
                >
                  Siguiente
                </button>
              </div> */}
              <Pagination
                className="w-full flex justify-center lg:justify-end py-4"
                pageSize={pageSize}
                current={pagination?.currentPage + 1}
                total={totalQuestions}
                onChange={handleChangePagination}
                showSizeChanger={false}
                itemRender={itemRender}
              />
            </div>
          </div>
        </>
      );
    default:
      break;
  }
};

const mapDispatchToProps = (dispatch) => ({
  clearOrganization: () => dispatch(clearOrganization()),
  dccLogoutCompany: () => dispatch(dccLogout()),
  unselected: (company) => dispatch(orgnizacionUnseleted(company)),
  logout: () => dispatch(logout()),
});

export default connect(null, mapDispatchToProps)(Test);
