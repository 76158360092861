//Libs
import React from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  selectCurrentUser,
  selectSessionExpired,
  selectMsgChangePassConfigUser,
} from "Redux/User/user-selectors";
import {
  setCurrentUser,
  changeMessageWhenIsExpired,
  changeStatePassConfigUser,
} from "Redux/User/user-actions";
import { setModuleOrganization } from "Redux/DynamicConfigCompany/dcc-action";
import { selectOrganizacion } from "Redux/DynamicConfigCompany/dcc-select";
import { setOrganizationSeleted } from "Redux/Organization/organizarion-action";

//selector
import { selectCompany } from "Redux/DynamicConfigCompany/dcc-select";
//Components
import FormInput from "Components/FormInput/FormInput";
import { CustomButton } from "Components";
import Spinner from "Components/Spinner/Spinner";
import logoAsocar from "Assets/logo-asocar.png";
//Services
import { login } from "Services/UserService";
import Mixpanel from "Services/MixPanelService";
//Config
import { ROUTES } from "Config/config";

class SignIn extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      email: "",
      password: "",
      isFailLogin: false,
    };
  }
  // this componentDidMonunt is used for change state redux when the tokan is expired
  componentDidMount() {
    if (this.props.sessionExpired) {
      setTimeout(() => {
        this.props.setChangeMessageExpiredStatus();
      }, 10000);
    }
    if (this.props.MsgLoginReload) {
      setTimeout(() => {
        this.props.changeStatePassConfigUser();
      }, 5000);
    }
  }
  handleSubmit = async (event) => {
    event.preventDefault();
    const { email, password } = this.state;
    const { setCurrentUser, company } = this.props;
    try {
      this.setState({
        loading: true,
      });
      const loginResult = await login(email, password, company.id);
      this.setState({
        loading: false,
      });
      if (!loginResult) {
        this.setState({
          isFailLogin: true,
        });
        setTimeout(() => {
          this.setState({
            isFailLogin: false,
          });
        }, 5000);
        return;
      }

      setCurrentUser(loginResult);

      if (loginResult.organizations.length <= 1) {
        this.props.setOrganizationModule(loginResult.organizations[0]);
        await this.props.setOrganizationSeleted(loginResult.organizations[0]);

        this.props.history.push(ROUTES.HOME);
      } else {
        this.props.history.push(ROUTES.ORGANIZATION);
      }
    } catch (err) {
      console.error(err);
    }
  };

  handleChange = (event) => {
    let { value, name } = event.target;

    if (name === "email") {
      value = value.toLowerCase();
    }

    this.setState({ [name]: value });
  };

  render() {
    const { loading, isFailLogin } = this.state;
    const { currentUser } = this.props;

    return currentUser ? (
      <Redirect to={ROUTES.HOME} />
    ) : (
      <div className="max-md:overflow-x-hidden w-screen">
        {loading && <Spinner />}
        <div
          className="min-h-full min-w-full w-full h-auto fixed top-0 left-0 bg-cover
           bg-center max-md:hidden bg-sign-in-background"
        />
        <div className="w-full flex justify-center max-md:block max-md:w-full">
          <div
            className="z-50 mt-60 w-1/2 max-md:w-full max-md:flex max-md:justify-center
            max-md:mt-10 max-md:mb-0 max-md:mr-0"
          >
            <img
              alt="Logo ASOCAR"
              className="w-vw-8 max-md:w-56"
              src={logoAsocar}
            />
          </div>
          <div
            className="mt-20 md:bg-primary-signin-box rounded-2xl h-auto p-10 w-85 relative top-0 
          max-md:mt-0 max-md:w-85 max-md:ml-auto max-md:mr-auto max-md:h-115 max-md:mb-5 max-md:bg-none"
          >
            <h1 className="text-center text-3xl mt-6 mb-6 text-primary-title font-bold	max-md:text-center max-md:ml-0">
              Iniciar Sesión
            </h1>
            {this.props.sessionExpired && (
              <p class="bg-red-600 text-primary-title p-3 rounded-md">
                Su sesión ha expirado o ha iniciado sesión en otro dispositivo.
              </p>
            )}
            {this.props.MsgLoginReload && (
              <p class="bg-red-600 text-primary-title p-3 rounded-md">
                Es necesario que vuelva a ingresar después de cambiar su
                contraseña.
              </p>
            )}
            <form
              action="POST"
              autoComplete="new-password"
              onSubmit={this.handleSubmit}
            >
              <FormInput
                name="email"
                type="email"
                maxLength={150}
                value={this.state.email}
                onChange={this.handleChange}
                label="Email"
                required
              />

              <FormInput
                name="password"
                type="password"
                value={this.state.password}
                onChange={this.handleChange}
                label="Contraseña"
                required
              />

              {isFailLogin === true && (
                <div className="text-center">
                  <span className="text-yellow-500">
                    Usuario o contraseña incorrecta
                  </span>
                </div>
              )}
              <div className="w-full flex justify-center">
                <div className="inline-flex flex-col justify-center mt-4 text-center w-8.5/12 max-md:w-11/12">
                  <CustomButton $singbutton>Iniciar Sesión</CustomButton>
                </div>
              </div>
            </form>
            <div className="w-full flex justify-center">
              <span className="text-primary-link text-center mr-1 text-pr">
                ¿Sos nuevo aquí?
              </span>
              <Link
                className="text-primary-link no-underline hover:no-underline hover:text-hover-link"
                to={ROUTES.SIGNUP}
              >
                Regístrate.
              </Link>
            </div>
            <div
              style={{ marginTop: "10px" }}
              className="w-full flex justify-center"
            >
              <span className="text-center mr-1 text-primary-link">
                ¿Olvidaste tu contraseña?
              </span>
              <Link
                to={ROUTES.FORGOT_PASSWORD}
                className="text-primary-link no-underline hover:no-underline hover:text-hover-link"
                onClick={() => {
                  Mixpanel.track(Mixpanel.TYPES.FORGOT_PASSWORD);
                }}
              >
                Recuperar.
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: selectCurrentUser(state),
  company: selectCompany(state),
  Organization: selectOrganizacion(state),
  sessionExpired: selectSessionExpired(state),
  MsgLoginReload: selectMsgChangePassConfigUser(state),
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
  setOrganizationModule: (organizationSelect) =>
    dispatch(setModuleOrganization(organizationSelect)),
  setOrganizationSeleted: (organization) =>
    dispatch(setOrganizationSeleted(organization)),
  setChangeMessageExpiredStatus: () => dispatch(changeMessageWhenIsExpired()),
  changeStatePassConfigUser: () => dispatch(changeStatePassConfigUser()),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(React.memo(SignIn));
