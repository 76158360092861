//Libs
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
//Actions
import { getRooms } from "Redux/Room/room-actions";
import { setVideoProperties } from "Redux/Video/video-actions";
import Slider from "react-slick";
//Components
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//Selectors
import { selectRooms } from "Redux/Room/room-selectors";
import {
  selectedOrganizationId,
  selectedOrganizationStyle,
} from "Redux/Organization/organization-select";
//Config
import { selectCurrentUser } from "Redux/User/user-selectors";

const RoomItem = React.lazy(() => import("Components/RoomItem/RoomItem"));

function LeftArrow({ style, onClick }) {
  return (
    <FontAwesomeIcon
      className="block absolute top-1/2 left-3 cursor-pointer text-5xl text-primary-left-arrow-room z-10 max-md:text-sm"
      icon={faChevronLeft}
      style={{
        transform: "translate(0, -50%)",
      }}
      onClick={onClick}
    />
  );
}

function RightArrow({ onClick }) {
  return (
    <FontAwesomeIcon
      icon={faChevronRight}
      className="block absolute top-1/2 right-3 cursor-pointer text-5xl text-primary-right-arrow-room z-10 max-md:text-sm"
      style={{
        transform: "translate(0, -50%)",
      }}
      onClick={onClick}
    />
  );
}

function RoomsGrid({
  rooms,
  getRooms,
  currentUser,
  mobileView,
  setVideoProperties,
  organizationId,
  organizationStyle,
}) {
  const [slideIndex, setSlideIndex] = useState(0);
  useEffect(() => {
    if (slideIndex > 0 && rooms.length < parseInt(rooms[0]?.totalRoomRows)) {
      getRooms(currentUser.token, { organizationId });
    }
  }, [slideIndex, currentUser.token, getRooms, organizationId, rooms]);

  return (
    <div className="relative m-0 max-h-screen max-md:-mt-11 max-md:mr-0 max-md:ml-0 max-md:mb-0">
      <Slider
        className="relative w-full"
        style={{
          height: mobileView
            ? "calc(100vw / 1.8)"
            : document.documentElement.clientHeight,
          maxHeight: "calc(100vw / 1.8)",
        }}
        {...{
          dots: false,
          infinite: true,
          speed: 500,
          autoplaySpeed: 4000,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          cssEase: "linear",
          afterChange: (current, next) => setSlideIndex(current),
          waitForAnimate: false,
          adaptiveHeight: true,
          prevArrow: <LeftArrow />,
          nextArrow: <RightArrow />,
        }}
      >
        {rooms.map((room) => (
          <RoomItem
            key={room.id}
            organizationStyle={organizationStyle}
            roomId={room.id}
            defaultRoomName={room.defaultName}
            roomName={room.name}
            setting={room.settings}
            resources={room.resources}
            streamId={room.streamId}
            streamWss={room.streamWss}
            defaultPosterUrl={room.defaultPosterUrl}
            defaultVideoUrl={room.defaultVideoUrl}
            liveDescription={room.liveDescription}
            mobileView={mobileView}
            currentUser={currentUser}
            setVideoProperties={setVideoProperties}
            renderAttributes={room.renderAttributes}
          />
        ))}
      </Slider>
    </div>
  );
}

const mapStateToProps = (state) => ({
  currentUser: selectCurrentUser(state),
  rooms: selectRooms(state),
  organizationId: selectedOrganizationId(state),
  organizationStyle: selectedOrganizationStyle(state),
});

const mapDispatchToProps = (dispatch) => ({
  getRooms: (token, whereOpts) => dispatch(getRooms(token, whereOpts)),
  setVideoProperties: (props) => dispatch(setVideoProperties(props)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RoomsGrid);
