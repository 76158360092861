//Libs
import React from "react";
import { Redirect } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { selectCurrentUser } from "Redux/User/user-selectors";
import { setCurrentUser } from "Redux/User/user-actions";
//Actions
import { setCompanyData } from "Redux/DynamicConfigCompany/dcc-action";
import { setModuleOrganization } from "Redux/DynamicConfigCompany/dcc-action";
import { setOrganizationSeleted } from "Redux/Organization/organizarion-action";
//Services
import { signUp, getRed } from "Services/UserService";
import MixPanel from "Services/MixPanelService";
//Config
import { ROUTES } from "Config/config";
//Selectors
import {
  selectCompany,
  selectOrganizacion,
} from "Redux/DynamicConfigCompany/dcc-select";
//Components
import FormInput from "Components/FormInput/FormInput";
import {
  Title,
  CustomButton,
  GoToSignin,
  ErrorLabel,
  Spinner,
} from "Components";

class SignUp extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      failMessage: true,
      partChurchs: [],
      networks: [],
      newUser: {
        name: "",
        firstLastName: "",
        secondLastName: "",
        dni: "",
        phone: "",
        email: "",
        professionalCode: "",
        church: "",
        organizationId: [],
        participationInOasis: "",
        password: "",
        confirmPassword: "",
        network: "",
      },
      modalState: {
        setModalCopyCRVisible: false,
        setModalPolicyCRVisible: false,
      },
    };
  }

  componentDidMount() {
    this.setState({
      networks: getRed(),
    });
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const {
      name,
      firstLastName,
      phone,
      email,
      organizationId,
      password,
      confirmPassword,
      church,
      secondLastName,
      network,
      participationInOasis,
    } = this.state.newUser;

    // VALIDATORS
    if (
      !name ||
      !firstLastName ||
      !phone ||
      !email ||
      !organizationId ||
      !church ||
      !network ||
      !participationInOasis
    ) {
      //MixPanel unsuccessful signup
      MixPanel.track(MixPanel.TYPES.UNSUCCESSFUL_SIGNUP);
      this.setState({
        failMessage: "Debe llenar todos los campos requeridos [*]",
      });
      setTimeout(() => {
        this.setState({
          failMessage: true,
        });
      }, 5000);
      return;
    }

    //Password
    if (password !== confirmPassword) {
      //MixPanel unsuccessful signup
      MixPanel.track(MixPanel.TYPES.UNSUCCESSFUL_SIGNUP);

      this.setState({ failMessage: "Las contraseñas no coinciden" });
      setTimeout(() => {
        this.setState({
          failMessage: true,
        });
      }, 5000);
      return;
    }

    try {
      this.setState({
        loading: true,
      });
      const signupResult = await signUp({
        ...this.state.newUser,
        companyId: this.props.company.id,
        countryId: this.props.company.countryId,
      });
      //MixPanel successFul signup
      MixPanel.track(MixPanel.TYPES.SUCCESSFUL_SIGNUP);
      this.setState({
        loading: false,
      });
      if (signupResult?.status === 409) {
        this.setState({
          failMessage: signupResult.response,
        });

        setTimeout(() => {
          this.setState({
            failMessage: true,
          });
        }, 5000);
        return;
      }
      if (!signupResult) {
        this.setState({
          failMessage: signupResult.response,
        });
        setTimeout(() => {
          this.setState({
            failMessage: true,
          });
        }, 5000);
        return;
      }
      if (signupResult.organizations.length <= 1) {
        this.props.setOrganizationModule(signupResult.organizations[0]);
        await this.props.setOrganizationSeleted(signupResult.organizations[0]);
      }
      this.props.setCurrentUser(signupResult);
    } catch (error) {
      this.setState({
        loading: false,
        failMessage:
          "Hubo un problema al crear la cuenta. Por favor vuelva a intentarlo",
      });
      setTimeout(() => {
        this.setState({
          failMessage: true,
        });
      }, 5000);
    }
  };

  handleChange = (event) => {
    let { name, value } = event.target;

    if (name === "email") {
      value = value.toLowerCase();
    }

    this.setState({
      newUser: {
        ...this.state.newUser,
        [name]: value,
      },
    });
  };

  handleChangeSelect = (name, value) => {
    if (name === "organizationId") {
      this.setState({
        newUser: {
          ...this.state.newUser,
          [name]: value,
        },
      });
    }

    if (name === "network") {
      this.setState({
        newUser: {
          ...this.state.newUser,
          [name]: value,
        },
      });
    }

    if (name === "participationInOasis") {
      this.setState({
        newUser: {
          ...this.state.newUser,
          [name]: value,
        },
      });
    }
  };

  render() {
    const { loading, failMessage } = this.state;
    const {
      name,
      phone,
      email,
      password,
      confirmPassword,
      firstLastName,
      secondLastName,
      church,
    } = this.state.newUser;
    const { currentUser } = this.props;
    //all organizacion company
    const { organizations } = this.props;
    return currentUser ? (
      <Redirect to={ROUTES.HOME} />
    ) : (
      <div className="w-screen">
        {loading && <Spinner />}
        <div
          className="bg-cover bg-center bg-sign-up-background min-h-full min-w-full 
         filter w-full h-auto fixed top-0 left-0 flex justify-end"
        />
        <form
          className="w-4/5 xl:w-1/4 mx-auto xl:mx-0 my-8 pt-5 pl-0 pb-8 pr-0
          flex flex-col shadow-none bg-primary-signin-box relative h-auto items-center rounded-lg xl:left-1/3"
          action="POST"
          onSubmit={this.handleSubmit}
        >
          <Title $loginTittle>Matricularse</Title>

          <FormInput
            name="name"
            type="name"
            maxLength={100}
            value={name}
            onChange={this.handleChange}
            label="Nombre completo*"
            required
          />
          <FormInput
            name="firstLastName"
            type="firstLastName"
            maxLength={100}
            value={firstLastName}
            onChange={this.handleChange}
            label="Primer apellido*"
            required
          />

          <FormInput
            name="secondLastName"
            type="secondLastName"
            maxLength={100}
            value={secondLastName}
            onChange={this.handleChange}
            label="Segundo apellido"
          />

          <FormInput
            name="phone"
            type="phone"
            maxLength={20}
            value={phone}
            onChange={this.handleChange}
            label="Número telefónico (WhatsApp)*"
            required
          />

          <FormInput
            name="email"
            type="email"
            maxLength={150}
            value={email}
            onChange={this.handleChange}
            label="Correo electrónico*"
            required
          />

          <FormInput
            name="password"
            type="password"
            maxLength={50}
            minLength={8}
            value={password}
            onChange={this.handleChange}
            label="Contraseña*"
            required
          />

          <FormInput
            name="confirmPassword"
            type="password"
            maxLength={50}
            minLength={8}
            value={confirmPassword}
            onChange={this.handleChange}
            label="Confirmar Contraseña*"
            required
          />
          {/* BLOQUES */}
          <div className="w-full flex justify-center">
            <select
              className="py-2 w-5/6 text-sm"
              style={{ color: "#446e7e", paddingLeft: "9%", fontSize: "17px" }}
              onChange={({ target }) =>
                this.handleChangeSelect("organizationId", target.value)
              }
            >
              <option
                disabled="disabled"
                className="relative"
                selected="selected"
              >
                Bloque a matricular*
              </option>
              {organizations.map(({ id, name }) => (
                <option key={id} value={id.toString()}>
                  {name}
                </option>
              ))}
            </select>
          </div>
          <span className="mx-5 my-1 text-primary-label-msg">
            ¿Ya has participado en un curso por medio de la plataforma
            somooasis.com?
          </span>
          <select
            className="py-2 w-5/6 text-sm"
            style={{ color: "#446e7e", paddingLeft: "9%", fontSize: "17px" }}
            onChange={({ target }) =>
              this.handleChangeSelect("participationInOasis", target.value)
            }
          >
            <option disabled="disabled" selected="selected">
              Elija una opción*
            </option>
            <option value={true}>Si</option>
            <option value={false}>No</option>
          </select>

          {/* You belong to a church */}
          <FormInput
            name="church"
            type="church"
            maxLength={100}
            value={church}
            onChange={this.handleChange}
            label="Iglesia dónde perteneces*"
          />

          <select
            className="py-2 w-5/6 text-sm mb-2"
            style={{ color: "#446e7e", paddingLeft: "9%", fontSize: "16px" }}
            onChange={({ target }) =>
              this.handleChangeSelect("network", target.value)
            }
          >
            <option disabled="disabled" selected="selected">
              Red*
            </option>
            {Array.isArray(this.state.networks) &&
              this.state.networks.map(({ network }) => (
                <option value={network}>{network}</option>
              ))}
          </select>

          <CustomButton $singupbutton>Matricularse</CustomButton>
          <ErrorLabel value={failMessage} />
          <GoToSignin />
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: selectCurrentUser(state),
  company: selectCompany(state),
  organizations: selectOrganizacion(state),
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
  setCompanyData: (data) => dispatch(setCompanyData(data)),
  setOrganizationModule: (organizationSelect) =>
    dispatch(setModuleOrganization(organizationSelect)),
  setOrganizationSeleted: (organization) =>
    dispatch(setOrganizationSeleted(organization)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  React.memo(SignUp)
);
