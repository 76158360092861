import React from "react";
import spinnerStyle from "Components/Spinner/spinnerStyle.module.css";
const Spinner = () => {
  return (
    <div
      style={{ borderTopColor: "transparent" }}
      className={`absolute top-2/4 left-2/4 w-16 h-16 border-4 border-green-600 border-solid
       rounded-full ${spinnerStyle.animateSpin} z-50`}
    ></div>
  );
};

export default Spinner;
