//Libs
import React, { Suspense, useEffect } from "react";
import { selectCurrentUser } from "Redux/User/user-selectors";
import { selectCompany } from "Redux/DynamicConfigCompany/dcc-select";
import { connect } from "react-redux";
import { compose } from "redux";
import { Redirect, Route, withRouter } from "react-router";
// import OneSignal from "react-onesignal";
//Hooks
import useResize from "Hooks/useResize";
import useScrolling from "Hooks/useScrolling";
//Components
import Header from "Components/Header/Header";
//Config
import { ROUTES, TAWK_PROPERTY_ID, TAWK_SITE_ID } from "Config/config";
//Dynamic module company
import injectComponents from "Navigation/injectComponents";
import injectSubComponent from "Navigation/injectSubComponent";
import { shouldRenderModule, shouldRenderHeader } from "Navigation/checks";
//Styles
import "Components/Loaders/FullScreanLoader/FullScreanLoader.css";
import "Components/Loaders/FullScreanLoader/EkgLoader.css";
import { changeModalUserInfoState } from "Redux/User/user-actions";
import tawkTo from "Utils/tawkTo";
import "./App.css";
const NavMenu = React.lazy(() => import("Components/Header/NavMenu"));

const routingDirectory = {
  [ROUTES.SIGNIN]: <Redirect to={ROUTES.HOME} />,
  [ROUTES.SIGNUP]: <Redirect to={ROUTES.HOME} />,
  [ROUTES.FORGOT_PASSWORD]: <Redirect to={ROUTES.HOME} />,
  [ROUTES.RESET_PASSWORD]: <Redirect to={ROUTES.HOME} />,
};

function App({ currentUser, modules, subModulos, location, company }) {
  //Hooks
  /**
   * WARNING:
   * useResize y useScrolling solo deben llamarse una vez en toda la app (Llamar en el top del arbol de componentes y repartir las props)
   */
  const resize = useResize();
  const { opaqueNavbar, scrollUp } = useScrolling();

  useEffect(() => {
    if (
      location.pathname.indexOf("room") === -1 &&
      location.pathname.indexOf("video") === -1
    ) {
      tawkTo(TAWK_PROPERTY_ID, TAWK_SITE_ID);
      const tawkToIframe = document.querySelector(
        "iframe[title='chat widget']"
      );
      if (tawkToIframe) {
        tawkToIframe.style.display = "block";
      }
    } else {
      const tawkToIframe = document.querySelector(
        "iframe[title='chat widget']"
      );
      if (tawkToIframe) {
        tawkToIframe.style.display = "none";
      }
    }
  }, [location]);

  // useEffect(() => {
  //   OneSignal.init({
  //     appId: "61ff51ec-b732-4958-8103-d175e83561a0",
  //   });
  // }, []);

  const Is404Route = (modules) => {
    return modules.reduce((acc, mod) => {
      if (
        window.location.pathname
          .substring(1, window.location.pathname.length)
          .includes(mod.modulePath)
      ) {
        acc = false;
      }
      return acc;
    }, true);
  };
  return (
    <div>
      <Suspense fallback={null}>
        <NavMenu
          location={location}
          modules={modules}
          currentUser={currentUser}
          mobileView={resize.mobileView}
        />
        {shouldRenderHeader(modules, location.pathname) && (
          <Header
            currentUser={currentUser}
            modules={modules}
            currentRoute={location.pathname}
            mobileView={resize.mobileView}
            opaqueNavbar={opaqueNavbar}
            scrollUp={scrollUp}
          />
        )}
        {injectComponents(modules).map(
          (module, idx) =>
            shouldRenderModule(currentUser, module) && (
              <Route
                key={idx}
                exact
                path={module.path || "hello"}
                render={(routeProps) => (
                  <module.component
                    location={routeProps.location}
                    mobileView={resize.mobileView}
                    slices={resize.slices}
                    sliceWidth={resize.sliceWidth}
                    sliceHeight={resize.sliceHeight}
                    sliceContainerHeight={resize.sliceContainerHeight}
                  />
                )}
              />
            )
        )}
        {Array.isArray(subModulos) &&
          injectSubComponent(subModulos).map(
            (module, idx) =>
              shouldRenderModule(currentUser, module) && (
                <Route
                  key={idx}
                  exact
                  path={module.path || "hello"}
                  render={(routeProps) => (
                    <module.component
                      location={routeProps.location}
                      mobileView={resize.mobileView}
                      slices={resize.slices}
                      sliceWidth={resize.sliceWidth}
                      sliceHeight={resize.sliceHeight}
                      sliceContainerHeight={resize.sliceContainerHeight}
                    />
                  )}
                />
              )
          )}

        {Is404Route(modules) && (
          <Route
            default
            render={() => {
              if (currentUser) {
                if (currentUser && currentUser.organizations.length <= 1) {
                  return <Redirect to={window.location} />;
                } else {
                  if (company.organizationSelect) {
                    if (currentUser) {
                      return <Redirect to={window.location} />;
                    }
                  } else {
                    return <Redirect to={ROUTES.ORGANIZATION} />;
                  }
                }
              } else {
                if (location.pathname === "/sign-up") {
                  return <Redirect to={window.location} />;
                }
                return <Redirect to={ROUTES.SIGNIN} />;
              }
            }}
          />
        )}
        <Route
          default
          render={() => {
            if (currentUser) {
              return routingDirectory[window.location.pathname];
            }
          }}
        />
      </Suspense>
    </div>
  );
}

const mapStateToProps = (state) => ({
  currentUser: selectCurrentUser(state),
  company: selectCompany(state),
});
const mapDispatchToProps = (dispatch) => ({
  changeStateModal: (state) => dispatch(changeModalUserInfoState(state)),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(App);
