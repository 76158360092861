import React from "react";

const TypeOfQuestion = ({ frameWorkOfQuestion, register }) => {
  const {
    answers,
    questionId,
    order,
    title,
  } = frameWorkOfQuestion;

  return (
    <div className="w-full pb-4 text-black text-justify">
      <span className="font-medium text-base lg:text-xl">
        {order}. {""} {title}
      </span>
      <div className="mt-3">
        {Array.isArray(answers) &&
          answers.map(({ text, id }, indeX) => (
            <div key={indeX} className="text-black flex flex-row">
              <label className="label">
                <input
                  type="radio"
                  id={`checkbox${questionId}`}
                  value={id}
                  className="radio-accent radio-md"
                  {...register(`checkbox${questionId}`)}
                />
                <span className="label-text text-black mx-3 text-base lg:text-xl">
                  {text}
                </span>
              </label>
            </div>
          ))}
      </div>
    </div>
  );
};

const Question = ({ frameWorkOfQuestion, register }) => {
  return (
    <TypeOfQuestion
      register={register}
      frameWorkOfQuestion={frameWorkOfQuestion}
    />
  );
};

export default Question;
