//Libs
import { useEffect, useState, useCallback } from "react";
//Config
import { DEVICE_IS_MOBILE, MOBILE_VIEWPORT_SIZE } from "Config/config";

// function vh(v) {
//   var h = Math.max(
//     document.documentElement.clientHeight,
//     window.innerHeight || 0
//   );
//   return (v * h) / 100;
// }

function vw(v) {
  var w = Math.max(
    document.documentElement.clientWidth,
    window.innerWidth || 0
  );
  return (v * w) / 100;
}

// function vmin(v) {
//   return Math.min(vh(v), vw(v));
// }

// function vmax(v) {
//   return Math.max(vh(v), vw(v));
// }

const TIME_DEBOUNCE = 100;

/* WARNING:
 * useResize solo debe llamarse una vez en toda la app (Llamar en el top del arbol de componentes y repartir las props)
 */
export default function useResize() {
  //Callbacks
  const calculateProperty = useCallback((prop) => {
    //Mobile
    if (DEVICE_IS_MOBILE || window.innerWidth <= MOBILE_VIEWPORT_SIZE) {
      let value = 49.5; //32.8;
      let unit = "vw";

      switch (prop) {
        case "slices":
          return 3; //Math.round(window.innerWidth / vw(value)) - 1;
        case "sliceWidth":
          return `${value}${unit}`;
        case "sliceHeight":
          return "calc(49.5vw * 0.59)";
        case "sliceContainerHeight":
          return "100%";
        default:
          return null;
      }
    } else {
      //Desktop

      let value = 19.7;
      let unit = "vw";

      switch (prop) {
        case "slices":
          return Math.round(window.innerWidth / vw(value));
        case "sliceWidth":
          return `${value}${unit}`;
        case "sliceHeight":
          return "calc(19.8vw * 0.59)";
        case "sliceContainerHeight":
          return "100%";
        default:
          return null;
      }
    }
  }, []);

  const [config, setConfiguration] = useState({
    //General
    mobileView: DEVICE_IS_MOBILE || window.innerWidth <= MOBILE_VIEWPORT_SIZE,
    //VideoGrid
    slices: calculateProperty("slices"),
    sliceWidth: calculateProperty("sliceWidth"),
    sliceHeight: calculateProperty("sliceHeight"),
    sliceContainerHeight: calculateProperty("sliceContainerHeight"),
  });

  //RESIZE EVENT
  useEffect(() => {
    let onResizeCallTimer = null;

    window.onresize = () => {
      clearTimeout(onResizeCallTimer);

      onResizeCallTimer = setTimeout(
        () =>
          setConfiguration({
            //General
            mobileView: window.innerWidth <= MOBILE_VIEWPORT_SIZE,
            //VideoGrid
            slices: calculateProperty("slices"),
            sliceWidth: calculateProperty("sliceWidth"),
            sliceHeight: calculateProperty("sliceHeight"),
            sliceContainerHeight: calculateProperty("sliceContainerHeight"),
          }),
        TIME_DEBOUNCE
      );
    };
  }, [calculateProperty]);

  return {
    mobileView: config.mobileView,
    slices: config.slices,
    sliceWidth: config.sliceWidth,
    sliceHeight: config.sliceHeight,
    sliceContainerHeight: config.sliceContainerHeight,
  };
}
