import React from "react";
import logoPolpo from "../../Assets/logo-polpo.svg";

const Footer = () => {
  return (
    <div
      className="block text-center h-8 border-t-2 border-solid 
      border-gray-650 pb-3 pt-3 w-11/12 my-0 mx-auto max-md:absolute max-md:bottom-2"
    >
      <div className="w-full flex justify-center relative bottom-6">
        <div className="flex-col items-center justify-center">
          <span
            className="text-white flex flex-row text-sm max-md:text-xs font-light cursor-pointer
          uppercase"
          >
            DESARROLLADO POR
            <a
              className="ml-1 no-underline text-gray-700"
              target="_blank"
              rel="noopener noreferrer" //Add this line, because security risk
              href="https://polpocr.com/"
            >
              <img
                className="h-10 max-md:h-5"
                alt="Logo empresa POLPO"
                src={logoPolpo}
              />
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
